<template>
    <div>
      <the-app-bar />
      <MerchantsProducts/>
      <Footer />
    </div>
  </template>
  
  <script>

  import Footer from "../../../components/footer.vue";
  import TheAppBar from "../../../components/NavBar/TheAppBar.vue";
  import MerchantsProducts from '../../../components/FoodHub/merchantsProducts.vue';
  export default {
    components: { TheAppBar, Footer, MerchantsProducts },
  };
  </script>
  
  <style lang="scss" scoped></style>
  